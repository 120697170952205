import { Reducer } from 'redux';
import { FundingActionTypes } from './actions';

export interface IFundingState {
  results: any[];
  result: any;
  exportResults: any[];
  loading: boolean;
  referenceLoading: boolean;
  exportLoading: boolean;
  error: string | null;
  totalPage: number;
  totalRecord: number;
  pageSize: number;
  currentPage: number;
  frameworkProgramme: any;
  status: any;
  isExporting: boolean;
  isSearchRequestError: boolean;
  isSearchRequestReferenceError: boolean;
  isExportRequestError: boolean;
}

export const initialState: IFundingState = {
  results: [],
  result: null,
  loading: false,
  referenceLoading: false,
  exportLoading: false,
  exportResults: [],
  error: null,
  totalPage: 0,
  totalRecord: 0,
  pageSize: 5,
  currentPage: 1,
  frameworkProgramme: [],
  status: [],
  isExporting: false,
  isSearchRequestReferenceError: false,
  isSearchRequestError: false,
  isExportRequestError: false
};

const fundingReducer: Reducer<IFundingState> = (state = initialState, action): IFundingState => {
  switch (action.type) {
    case FundingActionTypes.SET_IS_EXPORTING:
      return {...state, isExporting: action.payload}
    case FundingActionTypes.FETCH_FUNDINGS_SUCCESS:
      return { ...state, results: action.payload, loading: false, totalPage: action.totalPage, totalRecord: action.totalRecord, pageSize: action.pageSize, currentPage: action.currentPage, frameworkProgramme: action.frameworkProgramme, status: action.status };
    case FundingActionTypes.FETCH_FUNDING_BY_REFERENCE_SUCCESS:
      return { ...state, result: action.payload, loading: false, referenceLoading: false };
    case FundingActionTypes.FETCH_EXPORTS_SUCCESS:
      return {...state, exportResults: action.payload, exportLoading: false};
    case FundingActionTypes.CLEAR_EXPORT:
      return {...state, exportResults: [], exportLoading: false}
    case FundingActionTypes.SEARCH_REQUEST:
      return {...state, loading: true, isSearchRequestError: false};
    case FundingActionTypes.SEARCH_REQUEST_REFERENCE:
      return {...state, loading: false, referenceLoading: true};
    case FundingActionTypes.EXPORT_REQUEST:
      return {...state, loading: false, referenceLoading: false, exportLoading: true};
    case FundingActionTypes.SEARCH_REQUEST_FAILED:
      return {...state, isSearchRequestError: true}
    case FundingActionTypes.SEARCH_REQUEST_REFERENCE_FAILED:
      return {...state, isSearchRequestReferenceError: action.payload}
    case FundingActionTypes.EXPORT_REQUEST_ERROR:
      return {...state, isExportRequestError: action.payload, isExporting: action.loading}
    default:
      return state;
  }
};

export { fundingReducer };
