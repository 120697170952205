import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver';

export const exportToExcel = (excelData: any[], label: string, excludedFields: string[], renamedFields: Record<string, string>, fieldOrder: string[], baseUrl?: string) => {
    const Max_CELL_LENGTH = 32767;

    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 10)
      .replace(/-/g, '');

    const transformedData = excelData.map((row, rowIndex) => {
      const transformedRow: any = {};
      fieldOrder.forEach((field) => {
        if (excludedFields.includes(field)) return;
        const originalKey = Object.keys(renamedFields).find((key) => renamedFields[key] === field) || field;
        let value = row[originalKey];
  
        if (value !== undefined) {
          // Handle stringified arrays
          if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
            try {
              const parsed = JSON.parse(value);
              if (Array.isArray(parsed)) {
                value = parsed.join(', ');
              }
            } catch {
              value = value.slice(1, -1);
            }
          }

          // Use base url for the url filed
          if (baseUrl && field.toLowerCase() === 'url' && row['identifier']) {
            let parseIdentifier = JSON.parse(row['identifier']).join(', ');
            value = `${baseUrl}${parseIdentifier}`;
          }
  
          // Split large strings into chunks
          if (typeof value === 'string' && value.length > Max_CELL_LENGTH) {
            const chunks = [];
            for (let i = 0; i < value.length; i += Max_CELL_LENGTH) {
              chunks.push(value.slice(i, i + Max_CELL_LENGTH));
            }
            chunks.forEach((chunk, index) => {
              transformedRow[`${field}_part${index + 1}`] = chunk;
            });
          } else {
            transformedRow[field] = value;
          }
        }
      });

      return transformedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(transformedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${label}-${formattedDate}.xlsx`);
  };